import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
import { ProjectImage, ProjectImageDouble } from '../../components';
export const meta = {
  title: 'Project 7',
  hint: 'See project 7',
  order: 128,
  type: 'project',
  published: false,
  seoTitle: '',
  seoDescription: ''
};
export const content = {
  images: {
    hero: {
      src: 'https://via.placeholder.com/1600x900.png'
    },
    bottom: {
      src: 'https://via.placeholder.com/1600x900.png'
    },
    thumbnail: {
      src: 'https://via.placeholder.com/128x128.png'
    },
    img1: {
      src: 'https://via.placeholder.com/1200x675.png'
    },
    img2: {
      src: 'https://via.placeholder.com/600x900.png'
    },
    img3: {
      src: 'https://via.placeholder.com/600x900.png'
    },
    img4: {
      src: 'https://via.placeholder.com/1200x675.png'
    }
  },
  tags: ['branding', 'logo', 'typograhpy', 'style', 'feel'],
  excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium pretium tempor. Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet.',
  quote: 'quote-1',
  description: 'In hac habitasse platea dictumst. Vivamus adipiscing fermentum quam volutpat aliquam. Integer et elit eget elit facilisis tristique. Nam vel iaculis mauris. Sed ullamcorper tellus erat, non ultrices sem tincidunt euismod.',
  problem: 'In hac habitasse platea dictumst. Vivamus adipiscing fermentum quam volutpat aliquam. Integer et elit eget elit facilisis tristique. Nam vel iaculis mauris.',
  solution: 'In hac habitasse platea dictumst. Vivamus adipiscing fermentum quam volutpat aliquam. Integer et elit eget elit facilisis tristique. Nam vel iaculis mauris.',
  figures: [{
    value: '36%',
    label: 'Lorem ipsum sidor'
  }, {
    value: '27,000',
    label: 'Lorem ipsum sidor'
  }, {
    value: '20',
    label: 'Lorem ipsum sidor'
  }, {
    value: '150%',
    label: 'Lorem ipsum sidor'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ProjectImage image={content.images.img1} mdxType="ProjectImage" />
    <ProjectImageDouble images={[content.images.img2, content.images.img3]} mdxType="ProjectImageDouble" />
    <ProjectImage image={content.images.img4} mdxType="ProjectImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      